import * as React from "react"
import {Link} from "gatsby";

export default function ProductsRelative(props: any) {
  return (
    <div className="pt-4 pl-4 pr-4 section-container section-container--nearest">
      <div className="block-wrapper">
        <div className="row no-gutters pb-2" style={stylesInline.minHeight}>
          <div className="col-12"><h2 className="mb-1 mb-xl-3 text-white text-left pl-0 pl-xl-5">Related products</h2>
          </div>
        </div>
        <div className="product-list d-flex flex-wrap justify-content-start">
          {props.relatedProducts.slice(0, 4).map((item: any, index: any) => {
            const productPriceRange = item.priceRange.split("-")
            productPriceRange.length === 2 ? "&#163;" + productPriceRange[0] + " - &#163;" + productPriceRange[1] : item.priceRange
            return (
              <div key={index} className={"product-item"}>
                <Link
                  to={item.parentCategory && item.parentCategory.length ? "/products/" + item.parentCategory[0]?.slug + "/" + item.category?.slug + "/" + item.slug + "/" : "/products/" + item.category?.slug + "/" + item.slug + "/"}
                  className="follow-link d-block text-darkblue">
                  <div className={"product-item__wrapper d-flex flex-column bg-white"}>
                    <div className="product-item__image text-center">
                      {item.productMedias[0]?.thumbName ?
                        <img width={200} height={200} style={{overflow: "visible"}}
                             src={item.productMedias[0]?.awsUrl + item.productMedias[0]?.thumbName} alt={item.name}
                             loading="lazy"/> : ''}
                    </div>
                    <div className="product-item__content d-flex flex-column justify-content-center align-items-center">
                      {item.priceRange && item.parentCategory[0]?.slug != "headstone" && <div className="">
                        <p
                          className="text-deepblue product-item__price text-center mb-3">{productPriceRange.length === 2 ? "£" + parseInt(productPriceRange[0]).toLocaleString() + " - £" + parseInt(productPriceRange[1]).toLocaleString() : "£" + parseInt(item.priceRange).toLocaleString()}</p>
                      </div>}

                      {item.name && <div className="">
                        <h2 className="text-deepblue product-item__title text-center mb-0">{item.name}</h2>
                      </div>}
                      {item.parentCategory[0]?.slug === "headstone" && <h6 className="pt-2 font-italic text-center text-deepblue">For pricing, please contact Fenix</h6>}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
          }
        </div>
      </div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  minHeight: {
    minHeight: "unset",
  },
};