import * as React from "react";
import { HeadProps, PageProps } from "gatsby";

import TemplateHead from "../components/template-head";
import Layout from "../components/layout";
import Hero from "../components/hero";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import ProductContent from "../components/product-content";
import ProductsRelative from "../components/product-relative";

const products = require("../../src/data/fnx_products_uk.json");

type DataProps = {
  strapiLocation: any;
};

export default function ProductPage({ pageContext }: PageProps<DataProps>) {
  const productCategories = products.object.productMasterData.productCategories;
  const allowedCategories = [
    { id: 1, slug: "flowers" },
    { id: 2, slug: "coffins-and-caskets" },
    { id: 3, slug: "headstone" },
    { id: 4, slug: "urns" },
  ];

  const parentCategories = productCategories.filter((el) => {
    return allowedCategories.some((f) => {
      return f.slug === el.slug && el.parentId === null;
    });
  });

  const allProducts = products.object.products;

  allProducts.forEach((object) => {
    object.parentCategory = parentCategories.filter(function (el) {
      return el.id === object.category.parentId;
    });
  });

  const allVariableProducts = allProducts.filter(function (el) {
    return el.typeName === "Varianter";
  });

  const relatedProducts = allProducts.filter(function (el) {
    return (
      el.categoryId === pageContext?.categoryId && el.id != pageContext?.id
    );
  });

  const childVariableProducts = allVariableProducts.filter(function (el) {
    return el.parentIds[0] === pageContext?.id;
  });

  return (
    <Layout as="location">
      <Hero
        heroButton={"Back to products"}
        heroLink={"/products/"}
        imageGlow={"/images/glow.png"}
        sectionClassName="hero-section hero-section--product pl-4 pr-4"
        contentClassName="hero-content hero-content--location"
        bgImageClassName="hero-image hero-image--location"
        breadcrumb={
          pageContext?.categoryParentName
            ? [
                { name: "Fenix Home", slug: "/" },
                { name: "Products", slug: "/products/" },
                {
                  name: pageContext?.categoryParentName,
                  slug: "/products/" + pageContext?.categoryParentSlug + "/",
                },
                {
                  name: pageContext?.categoryName,
                  slug:
                    "/products/" +
                    pageContext?.categoryParentSlug +
                    "/" +
                    pageContext?.categorySlug +
                    "/",
                },
                {
                  name: pageContext?.name,
                  slug:
                    "/products/" +
                    pageContext?.categoryParentSlug +
                    "/" +
                    pageContext?.categorySlug +
                    "/" +
                    pageContext?.slug +
                    "/",
                },
              ]
            : [
                { name: "Fenix Home", slug: "/" },
                { name: "Products", slug: "/products/" },
                {
                  name: pageContext?.categoryName,
                  slug: "/products/" + pageContext?.categorySlug + "/",
                },
                {
                  name: pageContext?.name,
                  slug:
                    "/products/" +
                    pageContext?.categorySlug +
                    "/" +
                    pageContext?.slug +
                    "/",
                },
              ]
        }
      ></Hero>
      <ProductContent
        productContent={pageContext}
        variableProducts={childVariableProducts}
      />
      <ProductsRelative relatedProducts={relatedProducts} />
      <TrustpilotCarousel />
    </Layout>
  );
}

export function Head({ pageContext }: HeadProps<DataProps>) {
  const pageTitle = pageContext?.seoTitle
    ? pageContext?.seoTitle
    : pageContext?.name;
  const pageUrl = pageContext?.categoryParentSlug
    ? "https://fenixfuneral.co.uk/products/" +
      pageContext?.categoryParentSlug +
      "/" +
      pageContext?.categorySlug +
      "/" +
      pageContext?.slug +
      "/"
    : "https://fenixfuneral.co.uk/products/" +
      pageContext?.categorySlug +
      "/" +
      pageContext?.slug +
      "/";
  const pageDescription = pageContext?.seoMetaDescription
    ? pageContext?.seoMetaDescription
    : "Arrange beautiful funeral from £995. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning.";
  return (
    <TemplateHead
      title={pageTitle}
      shortlink={pageUrl}
      alternate={pageUrl}
      canonical={pageUrl}
      description={pageDescription}
      og={{
        url: pageUrl,
        title: pageTitle,
        description: pageDescription,
        image: pageContext?.productMediasUrl
          ? pageContext?.productMediasUrl
          : "https://fenixfuneral.co.uk/images/Logo-Dark.svg",
      }}
    />
  );
}
