import * as React from "react";
import {useEffect, useState} from "react";
import Cookies from "universal-cookie";

import {useFenixContext} from "../helpers/context";

const cookies = new Cookies();

export default function ProductContent(props: any) {
  const [variable, setVariable] = useState("0");
  const [variableProductPrice, setVariableProductPrice] = useState("");
  const cookieHub = cookies.get("fenix4hub");

  useEffect(() => {
    setVariableProductPrice(
      props.variableProducts.length != 0 ?
              cookieHub && props.variableProducts[0]?.pricePlaces.find(e => e.slug.includes(cookieHub)) ?
                "£" + props.variableProducts[0]?.pricePlaces.find(e => e.slug.includes(cookieHub))?.price :
                "£" + props.variableProducts[0]?.priceRange
              : cookieHub && cookieHub === props.productContent?.pricePlaces[0]?.slug ?
                "£" + props.productContent?.pricePlaces[0]?.price :
                "£" + props.productContent?.priceRange);
  }, []);

  function handleChange(e: any) {
    setVariable(e.target.selectedIndex);

    setVariableProductPrice(
  props.variableProducts.length != 0 ?
          cookieHub && props.variableProducts[e.target.selectedIndex].pricePlaces.find(e => e.slug.includes(cookieHub)) ?
            "£" + props.variableProducts[e.target.selectedIndex].pricePlaces.find(e => e.slug.includes(cookieHub))?.price :
            "£" + props.variableProducts[e.target.selectedIndex].priceRange
          : cookieHub && cookieHub === props.productContent.pricePlaces[0].slug ?
            "£" + props.productContent.pricePlaces[0].price :
            "£" + props.productContent.priceRange);
  }

  const {state, dispatch} = useFenixContext();
  const productPriceRange = props.productContent.priceRange !== null && props.productContent.priceRange.split("-")
  productPriceRange.length === 2 ? "&#163;" + productPriceRange[0] + " - &#163;" + productPriceRange[1] : props.productContent.priceRange
  console.log(props.productContent.categoryParentSlug)
  return (
    <div className="product-section pl-4 pr-4">
      <div className={"product-container bg-white"}>
        <div className="product-env">
          {
            <div className="product-featured text-center">
              {props.productContent.productMediasUrl ?
                <img src={props.productContent.productMediasUrl} alt={props.productContent?.name} width={450}
                     height={350}/> :
                <img src="/images/default-product.jpg" alt={props.productContent?.name} width={450} height={350}/>}
            </div>
          }
          <div className="product-content text-deepblue">
            <div className="product-header">
              <h1 className="text-center pb-3">{props.productContent?.name}</h1>

              <p className="text-center">{props.productContent.categoryParentSlug != "headstone" ? variableProductPrice : "For pricing, please contact Fenix"}</p>
              {props.variableProducts.length != 0 && <div className="form-group text-center text-deepblue mx-auto">
                <label htmlFor="productVariant"><b>Variants</b></label>
                <div className="product-dropdown mx-auto">
                  <select
                    className="form-control" id="productVariant"
                    defaultValue={props.variableProducts[0]?.name}
                    onChange={handleChange}
                  >
                    {props.variableProducts.map((item: any, index: any) => (
                      <option key={index} id={"option" + index} value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>}
            </div>
            {props.productContent?.description && <div id={"productText"} className={"product-text text-center"}>
              <div className="mt-4"><b>{props.productContent?.description}</b></div>
            </div>}
            <hr/>
            <div className="d-flex justify-content-center mb-4">
              <button
                className="bg-deepblue text-white m-auto gtm-strongintent"
                onClick={() => dispatch({type: "modalBooking"})}
              >Book consultation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};